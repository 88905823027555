<script setup lang="ts">
interface Props {
  size?: "small" | "medium" | "large";
}

const props = withDefaults(defineProps<Props>(), {
  size: "medium",
});

const classObject = computed(() => ({
  [`is-${props.size}`]: props.size,
}));
</script>

<template>
  <div class="avatar" :class="classObject">
    <img src="@/assets/img/avatar.png" class="image" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.avatar {
  background: $color-white;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba($color-primary, 0.1);

  &.is-small {
    width: 3rem;
    height: 3rem;
  }
  &.is-medium {
    width: 6rem;
    height: 6rem;
  }
  &.is-large {
    width: 8rem;
    height: 8rem;
  }
}

.image {
  width: 100%;
  height: auto;
}
</style>
